import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import moment from "moment-timezone";
import EventModule from "@/store/module/shared/EventModule";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import UsersTable from "@/components/admin/users/UsersTable.vue";
import EventParticipants from "@/components/admin/dialogs/EventParticipants/EventParticipants.vue";
import AccreditationDialog from "@/components/admin/dialogs/Event/Accreditation.vue";
import ApproveDialog from "@/components/shared/Approve.vue";
import EventDialog from "@/components/shared/dialogs/Event.vue";
let EventPage = class EventPage extends Vue {
    constructor() {
        super(...arguments);
        this.refreshNonParticipants = false;
        this.accreditation = {
            dialog: false,
        };
        this.updateEvent = {
            dialog: false,
        };
        this.addParticipants = {
            dialog: false,
        };
        this.approve = {
            ianseoExport: false,
            downloadReport: false
        };
        this.clearSelected = false;
        this.selectedUsers = [];
        this.event = null;
        this.participantsCategoriesCodes = {};
        this.showGrouping = true;
        this.participantsHeaders = [
            {
                text: "Амплуа",
                sortable: true,
                value: "ampluaView",
            },
            {
                text: "Дисциплина",
                sortable: true,
                value: "disciplineView",
            },
            {
                text: "Категория",
                sortable: true,
                value: "categoryView",
            },
            {
                text: "Фамилия",
                sortable: true,
                value: "surname",
            },
            {
                text: "Имя",
                sortable: true,
                value: "name",
            },
            {
                text: "Отчество",
                sortable: true,
                value: "patronymic",
                align: "center",
            },
            {
                text: "Дата рождения",
                sortable: true,
                value: "birthdate",
            },
            {
                text: "№ билета",
                sortable: true,
                value: "idRfsl",
            },
            {
                text: "Срок действия",
                sortable: true,
                value: "expiryDate",
            },
            {
                text: "Дата вступления",
                sortable: true,
                value: "entryDate",
            },
            { text: "", value: "data-table-expand" },
        ];
        this.participantsHeadersUserView = [
            {
                text: "Амплуа",
                sortable: true,
                value: "ampluaView",
            },
            {
                text: "Дисциплина",
                sortable: true,
                value: "disciplineView",
            },
            {
                text: "Категория",
                sortable: true,
                value: "categoryView",
            },
            {
                text: "Фамилия",
                sortable: true,
                value: "surname",
            },
            {
                text: "Имя",
                sortable: true,
                value: "name",
            },
            {
                text: "Отчество",
                sortable: true,
                value: "patronymic",
                align: "center",
            },
        ];
        this.amplua = {
            ref: "amplua",
            type: "select",
            label: "Амплуа",
            value: null,
            items: () => this.ampluaHandbook,
            onInput: () => {
                if (this.amplua.value === 1) {
                    this.category.disabled = false;
                }
                else {
                    this.category.disabled = true;
                }
            },
            showOnlyForSuperAuth: false,
            rules: [],
            show: true,
        };
        this.category = {
            ref: "category",
            type: "select",
            label: "Категория",
            value: null,
            items: () => this.eventCategories,
            onInput: () => { },
            showOnlyForSuperAuth: false,
            rules: [],
            show: true,
            disabled: true,
        };
        this.filters = [this.amplua, this.category];
        this.ianseoProtocol = {
            ref: "ianseoProtocol",
            type: "fileupload",
            icon: "mdi-file-document-plus",
            label: "",
            placeholder: "Загрузите документ с расширением .ianseo",
            value: "",
            accept: "*/*",
            onChange: async (file) => {
                if (file === null)
                    return;
                const fd = new FormData();
                fd.append("protocol", file);
                fd.append("eventId", this.$route.params.id);
                this.$notify({
                    title: "Ожидайте загрузки и обработки протокола...",
                });
                const response = await this.$store.dispatch(EventModule.types.actions.UPLOAD_IANSEO_PROTOCOL, { fd });
                if (!response.error) {
                    await this.onEventChanged();
                    this.$notify({
                        title: "Протокол успешно загружен и обработан",
                        type: "success",
                    });
                }
                else {
                    this.$notify({
                        title: response.error,
                        type: "error",
                    });
                }
            },
            showOnlyForSuperAuth: true,
            show: true,
        };
        this.lastPickedPosition = 0;
    }
    setSelectedUsers(users) {
        this.selectedUsers = users;
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get canEdit() {
        if (this.isSuperAdmin)
            return true;
        if (this.isAdmin && this.admin.id === this.event.creatorId)
            return true;
        return false;
    }
    get isRemoveButtonIsDisabled() {
        return this.selectedUsers.length === 0;
    }
    get canAddParticipants() {
        if (!this.event)
            return false;
        if (!this.isAdmin)
            return false;
        const byRegion = this.event.type.regionalStatusId === 1 ||
            this.event.type.regionalStatusId === 4 ||
            this.event?.address?.regionId === this.admin.regionId;
        if (byRegion)
            return true;
        return false;
    }
    get eventsTable() {
        return this.$store.getters[EventModule.types.getters.EVENTS_TABLE];
    }
    get avatar() {
        if (!this.event) {
            return `${IMAGE_SERVER_URL}/public/archery-event-placeholder.jpg`;
        }
        return `${IMAGE_SERVER_URL}/public/${this.event.logo}`;
    }
    get participants() {
        let participants = this.event.participants.filter((participant) => {
            if (!participant.user)
                return false;
            if (participant.user.isPremoderated)
                return false;
            if (this.amplua.value && participant.ampluaId !== this.amplua.value)
                return false;
            if (this.category.value && participant.categoryId !== this.category.value)
                return false;
            if (!this.isSuperAdmin && this.isAdmin) {
                return participant.user.address !== null && participant.user.address.regionId === this.admin.regionId;
            }
            return true;
        });
        const items = {
            participants: participants.map((participant, index) => {
                let disciplineView = "";
                if (participant.discipline && participant.discipline.discipline) {
                    disciplineView = `${participant.discipline.disciplineGroup.name} | ${participant.discipline.discipline.name}`;
                }
                else if (participant.discipline && participant.discipline.disciplineGroup) {
                    disciplineView = participant.discipline.disciplineGroup.name;
                }
                return {
                    ...participant.user,
                    ampluaView: participant.amplua.name,
                    categoryView: participant.category && participant.category.category
                        ? `${participant.category.category.name} от ${participant.category.category.ageCategory.ageFrom} до ${participant.category.category.ageCategory.ageTo} лет`
                        : "",
                    disciplineView,
                    participant,
                };
            }),
        };
        let formalizedGroupings = Object.values(this.participantsCategoriesCodes);
        if (this.lastPickedPosition !== 0) {
            const sortedGroupings = Object.values(this.participantsCategoriesCodes).sort((a, b) => {
                if (a.position === null && b.position === null)
                    return 0;
                if (a.position !== null && b.position === null)
                    return 1;
                if (a.position === null && b.position !== null)
                    return -1;
                return a.position - b.position;
            });
            sortedGroupings.reverse().map((g, index) => {
                if (g.position === null) {
                    formalizedGroupings.push(g);
                }
                else {
                    formalizedGroupings.unshift(g);
                }
            });
        }
        let groupedItems = {
            participants: [],
        };
        const indexTable = {};
        for (let grouping of formalizedGroupings) {
            if (grouping.category !== null && grouping.discipline !== null) {
                let group = items.participants.filter((user) => {
                    if (Object.prototype.hasOwnProperty.call(indexTable, user.participant.id))
                        return false;
                    const age = moment().diff(moment(user.birthdate), "years");
                    return (user.participant?.category?.categoryId === grouping.category?.categoryId &&
                        user.participant?.discipline.disciplineGroupId === grouping.discipline.disciplineGroupId &&
                        age >= grouping.minAge &&
                        age <= grouping.maxAge &&
                        user.participant.ampluaId === 1);
                });
                group = group.map((g, index) => {
                    indexTable[g.participant.id] = index;
                    return {
                        ...g,
                        groupingCode: grouping.code,
                    };
                });
                groupedItems.participants = groupedItems.participants.concat(group);
            }
            else {
                const hasMultiplyCodes = grouping.code.match(/\,/) !== null;
                let codes = [grouping.code];
                if (hasMultiplyCodes) {
                    codes = grouping.code.split(",");
                }
                for (let code of codes) {
                    let group = items.participants.filter((user) => {
                        if (Object.prototype.hasOwnProperty.call(indexTable, user.participant.id))
                            return false;
                        return user.participant?.amplua?.code === code;
                    });
                    group.map((g, index) => {
                        indexTable[g.participant.id] = index;
                    });
                    groupedItems.participants = groupedItems.participants.concat(group);
                }
            }
        }
        for (let user of items.participants) {
            if (!Object.prototype.hasOwnProperty.call(indexTable, user.participant.id)) {
                groupedItems.participants.push(user);
            }
        }
        return groupedItems;
    }
    get disciplinesTree() {
        const tree = [];
        if (this.event === null || this.event === undefined)
            return [];
        this.event.disciplines.map((item) => {
            let lastTreeItemIndex = -1;
            const parent = tree.find((fItem) => fItem.id === item.disciplineGroupId);
            if (parent === undefined) {
                lastTreeItemIndex =
                    tree.push({
                        ...item.disciplineGroup,
                        children: [],
                    }) - 1;
                if (item.discipline !== null) {
                    tree[lastTreeItemIndex].children.push(item.discipline);
                }
            }
            else if (item.discipline !== null) {
                parent.children.push(item.discipline);
            }
        });
        return tree;
    }
    get categoriesNames() {
        if (this.event === undefined || this.event === null)
            return [];
        return this.event.categories.map((item) => `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`);
    }
    get ampluaHandbook() {
        return [...this.$store.getters[HandbookModule.types.getters.AMPLUA]].reverse();
    }
    get eventCategories() {
        if (!this.event)
            return [];
        return this.event.categories.map((item) => {
            return {
                ...item.category,
                name: `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`,
                id: item.id,
            };
        });
    }
    get showResultsUploading() {
        return moment(this.event.endDate).isBefore(moment()) && this.isSuperAdmin;
    }
    get hasResults() {
        return !!this.event.ianseoProtocol;
    }
    get filtersValues() {
        return {
            ampluaId: this.amplua.value || null,
            categoryId: this.category.value || null,
            usersIds: this.selectedUsers.map((u) => u.id),
        };
    }
    get profile() {
        if (!this.event)
            return {
                main: [],
                additional: [],
                sport: [],
            };
        const main = [
            { title: "Название", value: this.event.name },
            { title: "Тип", value: this.event.type.name },
            {
                title: "Начало",
                value: moment(this.event.startDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
            },
            {
                title: "Конец",
                value: moment(this.event.endDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
            },
            {
                title: "Конец регистрации",
                value: moment(this.event.registrationEndDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
            },
            { title: "Регистрация открыта", value: this.event.isOpenForRegistration === true ? "Да" : "Нет" },
            { title: "Тип события", value: this.event.isPrivate ? "Закрытое" : "Открытое" },
            { title: "Наличие взноса", value: this.event.participationFee === null ? "Нет" : "Есть" },
        ];
        if (this.event.participationFee !== null) {
            main.push({
                title: "Сумма взноса",
                value: this.event.participationFee + " руб.",
            });
        }
        return {
            main,
            additional: [
                {
                    title: "Федеральный округ",
                    value: this.event.address === null ? "Не указан" : this.event.address.region.district.name,
                    isTree: false,
                },
                {
                    title: "Регион",
                    value: this.event.address === null ? "Не указан" : this.event.address.region.name,
                    isTree: false,
                },
                {
                    title: "Город",
                    value: this.event.address !== null && this.event.address.city !== null
                        ? this.event.address.city.name
                        : "Не указан",
                    isTree: false,
                },
            ],
            sport: [
                { title: "Категории", value: "<br><br>" + this.categoriesNames.join("<br>"), isTree: false },
                { title: "Дисциплины", value: this.disciplinesTree, isTree: true },
            ],
        };
    }
    get limits() {
        if (!this.event)
            return [];
        const limitKey = (categoryId, disciplineId) => `${categoryId}-${disciplineId}`;
        const participantsCountTable = new Map();
        for (const participant of this.event.participants) {
            const key = limitKey(participant.categoryId, participant.disciplineId);
            if (!participantsCountTable.has(key)) {
                participantsCountTable.set(key, 1);
            }
            else {
                const currentCount = participantsCountTable.get(key);
                participantsCountTable.set(key, currentCount + 1);
            }
        }
        const limitsTable = new Map();
        const items = [];
        this.event.limits.map((l) => limitsTable.set(limitKey(l.eventCategoryId, l.eventDisciplineId), l.limit));
        for (const category of this.event.categories) {
            for (const discipline of this.event.disciplines) {
                const key = limitKey(category.id, discipline.id);
                const limit = limitsTable.get(key);
                const participantsCount = participantsCountTable.get(key) === undefined ? 0 : participantsCountTable.get(key);
                const disciplineName = discipline?.discipline?.name
                    ? discipline.discipline.name
                    : discipline.disciplineGroup.name;
                const currentlyParticipated = participantsCount > limit ? limit : participantsCount;
                const reserve = participantsCount - limit;
                const reserveView = reserve > 0 ? ` <span class="teal--text accent-2">(резерв ${reserve})</span>` : "";
                const limitView = limit === this.$store.getters[EventModule.types.getters.UNLIMITED_PARTICIPANTS_VALUE]
                    ? ""
                    : ` из ${limit}${reserveView}`;
                items.push({
                    title: `${disciplineName} - ${category.category.name} от ${category.category.ageCategory.ageFrom} до ${category.category.ageCategory.ageTo}: `,
                    value: `${currentlyParticipated}${limitView}`,
                });
            }
        }
        return items;
    }
    async removeParticipants() {
        if (this.selectedUsers.length === 0)
            return;
        const participants = this.selectedUsers.map((user) => ({
            id: this.event.participants.find((item) => item.userId === user.id).id,
            eventId: this.event.id,
            userId: user.id,
        }));
        await this.$store.dispatch(EventModule.types.actions.DELETE_PARTICIPANTS, {
            eventId: this.event.id,
            participantsIds: participants.map((p) => p.id),
        });
        this.$notify({
            type: "success",
            title: "Выбранные члены РФСЛ успешно удалены из списка участников",
        });
        await this.onEventChanged();
        this.clearSelected = !this.clearSelected;
    }
    async ianseoExport() {
        await this.$store.dispatch(EventModule.types.actions.IANSEO_EXPORT_GROUPED, {
            eventId: this.event.id,
            participants: this.selectedUsers.length > 0 ? this.selectedUsers : this.participants.participants,
            adminId: this.admin.id,
        });
    }
    async downloadReport() {
        await this.$store.dispatch(EventModule.types.actions.DOWNLOAD_RESULTS_REPORT_FOR_EVENT, {
            eventId: this.event.id,
        });
    }
    setParticipantCategoriesCodes() {
        const state = {
            shared: {
                exists: false,
                male: null,
                female: null,
            },
            junior: {
                exists: false,
                male: null,
                female: null,
            },
            young: {
                exists: false,
                male: null,
                female: null,
            },
            old: {
                exists: false,
                male: null,
                female: null,
            },
        };
        const ageCategoryIdMapping = {
            1: "shared",
            2: "junior",
            3: "young",
            4: "old",
        };
        for (const category of this.event.categories) {
            state[ageCategoryIdMapping[category.category.ageCategoryId]].exists = true;
            state[ageCategoryIdMapping[category.category.ageCategoryId]][category.category.gender] = category;
        }
        if (state.shared.exists) {
            state.junior.exists = true;
            state.young.exists = true;
        }
        for (const discipline of this.event.disciplines) {
            const groupCode = discipline?.disciplineGroup?.code ? discipline.disciplineGroup.code : "";
            const stateKeys = Object.keys(state);
            for (const stateKey of stateKeys) {
                const stateItem = state[stateKey];
                if (!stateItem.exists)
                    continue;
                const genderItems = [stateItem.male, stateItem.female];
                for (const category of genderItems) {
                    if (!category)
                        continue;
                    this.participantsCategoriesCodes[`${groupCode}${category.category.code}`] = {
                        position: null,
                        category,
                        discipline,
                        code: `${groupCode}${category.category.code}`,
                        minAge: category.category.ageCategory.ageFrom,
                        maxAge: category.category.ageCategory.ageTo,
                    };
                }
            }
        }
        this.participantsCategoriesCodes["C,OF,TO"] = {
            position: null,
            category: null,
            discipline: null,
            code: "C,OF,TO",
            minAge: 13,
            maxAge: 100,
        };
        this.participantsCategoriesCodes["J"] = {
            position: null,
            category: null,
            discipline: null,
            code: "J",
            minAge: 13,
            maxAge: 100,
        };
        console.log(this.participantsCategoriesCodes);
    }
    calculateGroupingPositions(code) {
        let lastPosition = 0;
        Object.values(this.participantsCategoriesCodes).map((v) => {
            if (v.position === null)
                return;
            if (v.position > lastPosition && v.position - lastPosition > 1) {
                lastPosition = v.position + 1;
            }
            else if (v.position > lastPosition)
                lastPosition = v.position;
        });
        this.participantsCategoriesCodes[code].position = lastPosition + 1;
    }
    pickGrouping(meta, code) {
        if (this.participantsCategoriesCodes[code].position === null) {
            this.participantsCategoriesCodes[code].position = ++this.lastPickedPosition;
        }
        else {
            const removedPosition = this.participantsCategoriesCodes[code].position;
            this.participantsCategoriesCodes[code].position = null;
            this.lastPickedPosition--;
            Object.keys(this.participantsCategoriesCodes).map((code) => {
                if (this.participantsCategoriesCodes[code].position > removedPosition) {
                    this.participantsCategoriesCodes[code].position--;
                }
            });
        }
        this.showGrouping = false;
        this.showGrouping = true;
    }
    async onEventChanged() {
        const response = await this.$store.dispatch(EventModule.types.actions.LOAD_EVENT, {
            id: parseInt(this.$route.params.id, 10),
        });
        this.event = response;
        this.refreshNonParticipants = !this.refreshNonParticipants;
    }
    async mounted() {
        const eventId = parseInt(this.$route.params.id, 10);
        if (Object.keys(this.eventsTable).length === 0) {
            await this.$store.dispatch(EventModule.types.actions.GET_EVENTS);
            this.event = this.eventsTable[eventId];
        }
        else {
            this.event = this.eventsTable[eventId];
        }
        this.category.items = () => this.eventCategories;
        this.setParticipantCategoriesCodes();
    }
};
EventPage = __decorate([
    Component({
        components: {
            EventDialog,
            UsersTable,
            EventParticipants,
            AccreditationDialog,
            ApproveDialog,
        },
    })
], EventPage);
export default EventPage;
